<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class="col-5">
          <h5 class="page-header">Brief details</h5>
        </div>
        <div class="col-2">
          <p v-if="$apollo.loading" class="badge bg-light">Load</p>
          <h3
            v-else
            class="badge fs-6"
            :class="{
              'bg-warning': brief.activities[0].status.name === 'proposed',
              'bg-success': brief.activities[0].status.name === 'approved',
              'bg-dark': brief.activities[0].status.name === 'rejected',
              'bg-secondary': brief.activities[0].status.name === 'delivered',
            }"
          >
            Status: {{ brief.activities[0].status.label }}
          </h3>
        </div>
        <div class="col-5 text-end">
          <a class="btn mx-3 btn-secondary text-center" role="button" @click="$router.push({ name: 'home' })">Cancel</a>
          <BaseLink class="btn mx-3 btn-primary text-center" :to="{ name: 'briefs.new', params: { cloneFromBriefId: id } }">Clone Brief</BaseLink>
          <a class="btn mx-3 btn-success text-center" role="button" :disabled="!userCanEdit" @click="updateBrief"
            >Save and close</a
          >
        </div>
      </div>
      <hr class="dotted-spacer" />

      <BaseAlert v-if="showAlert" class="mb-4" :type="alert.type">
        <span slot="title">{{ alert.title }}</span>
        <ul v-if="typeof alert.message === 'object'" slot="message">
          <li v-for="m in alert.message" :key="m">{{ m }}</li>
        </ul>
        <span v-else slot="message">{{ alert.message }}</span>
      </BaseAlert>

      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading brief data</p>
        </div>
      </div>
      <div v-else class="row pt-4 justify-content-around">
        <div class="col">
          <ValidationObserver ref="form" v-slot="{}">
            <form @submit.prevent="updateBrief">
              <!-- Activities -->
              <div v-for="(activity, index) in brief.activities" :key="'activity-' + index" class="row">
                <div class="col-xl-2 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Dates: '">
                    <BaseDatepicker
                      v-model="activity.dates"
                      type="text"
                      name="dates"
                      @input="checkForBlockingReminders"
                    />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Locations:'">
                    <BaseMultiselect
                      v-model="activity.locations"
                      :multiple="true"
                      :taggable="false"
                      :options="locations"
                      track-by="name"
                      name="locations"
                    />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-6">
                  <BaseFormGroup :size="'md'" :label="'Channel:'">
                    <BaseMultiselect
                      v-model="activity.channel"
                      :multiple="false"
                      :taggable="false"
                      :options="channels"
                      track-by="name"
                      name="channels"
                    />
                  </BaseFormGroup>
                </div>
                <div class="col-xl-3 col-lg-5">
                  <BaseFormGroup :size="'md'" :label="'Spokespersons:'">
                    <BaseMultiselect
                      v-model="activity.spokespersons"
                      :multiple="true"
                      :taggable="false"
                      :options="spokespersons"
                      track-by="name"
                      name="spokespersons"
                    />
                  </BaseFormGroup>
                </div>
                <div
                  v-if="index == brief.activities.length - 1 && brief.activities.length > 1"
                  class="col-xl-1 col-lg-1"
                >
                  <div class="text-end my-3">
                    <a class="link-danger fs-4" @click="removeActivity(index)"><BaseIcon :name="['fas', 'times']" /></a>
                  </div>
                </div>
              </div>
              <div class="row mt-2 plusrow">
                <div class="col-1 offset-11 text-start text-primary">
                  <a class="link-primary fs-5" @click="addActivity">Add activity</a>
                </div>
              </div>

              <!-- Brief -->
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Subject (max 80 characters):'">
                    <ValidationProvider v-slot="{ errors }" name="subject" rules="required">
                      <BaseInput
                        v-model="brief.title"
                        v-tooltip.top-center="'This will be displayed alongside the location and channel on the calendar'"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        maxlength="80"
                        :disabled="!userCanEdit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Description:'">
                    <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                      <BaseInput
                        v-model="brief.proposedDetails"
                        type="textarea"
                        name="description"
                        placeholder="Description"
                        :rows="5"
                        :disabled="!userCanEdit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4 justify-content-between">
                <div class="col-xl-6 col-lg-8">
                  <BaseFormGroup :size="'md'" :label="'Key contact:'">
                    <ValidationProvider v-slot="{ errors }" name="owner" rules="required">
                      <BaseMultiselect
                        v-model="brief.owner"
                        :multiple="false"
                        :taggable="false"
                        :options="teamMembers"
                        track-by="name"
                        name="owner"
                        :disabled="!userCanEdit"
                        :error="errors[0]"
                        :allow-empty="false"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <BaseFormGroup :size="'sm'" :label="'Confidential:'">
                    <BaseInput v-model="brief.confidential" type="checkbox" name="confidential"></BaseInput>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Team:'">
                    <ValidationProvider v-slot="{ errors }" name="team">
                      <BaseMultiselect
                        v-model="brief.team"
                        :multiple="true"
                        :taggable="false"
                        :options="teamMembers"
                        track-by="name"
                        name="asignees"
                        :disabled="!userCanEdit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <BaseFormGroup :size="'lg'" :label="'Tags:'">
                    <ValidationProvider v-slot="{ errors }" name="tags">
                      <BaseMultiselect
                        v-model="brief.tags"
                        :multiple="true"
                        :taggable="false"
                        :options="tags"
                        track-by="name"
                        name="tags"
                        :disabled="!userCanEdit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </BaseFormGroup>
                </div>
              </div>

              <hr class="dotted-spacer mt-4" />

              <div class="row mt-5 mb-5">
                <div class="col col-md-2">
                  <label class="pt-2 col-form-label text-primary-dark fw-bolder align-middle">Change status:</label>
                </div>
                <div class="col col-md-6 col-xl-3">
                  <BaseMultiselect
                    :value="brief.activities[0].status"
                    :options="statuses"
                    track-by="id"
                    name="statuses"
                    :disabled="!userCanEdit"
                    :allow-empty="true"
                    @input="inputStatus($event)"
                  />
                </div>
                <div class="col col-xl-2 col-lg-6">
                  <BaseButton
                    class="btn-success"
                    type="button"
                    :disabled="$apollo.loading || !userCanEdit"
                    @click="updateStatus"
                  >
                    Update Status
                  </BaseButton>
                </div>
              </div>

              <!-- <div class="row mt-4">
                <div class="col-2 offset-10 text-end">
                  <BaseButton
                    type="submit"
                    :disabled="$apollo.loading || !userCanEdit"
                    :classes="'btn-success btn-lg'"
                    @click="updateBrief"
                  >
                    <span>Save all changes</span>
                  </BaseButton>
                </div>
              </div> -->
            </form>
          </ValidationObserver>
        </div>
      </div>

      <hr class="dotted-spacer my-5" />

      <div class="row pt-4 justify-content-around">
        <div class="col">
          <BaseFormGroup :label="'Audit trail:'" :size="'lg'">
            <ul class="list-group">
              <li
                v-for="(log, index) in briefLogs"
                :key="log.id"
                class="list-group-item list-group-item-dark font-display"
              >
                {{ log.createdAt }} – Brief {{ log.statusTo ? (index === briefLogs.length-1 ? "created with status " : "moved to ") +
                log.statusTo.label : "updated" }} by
                {{ log.createdBy.name || log.createdBy.email }}
              </li>
            </ul>
          </BaseFormGroup>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { GetTags, GetPeople, GetChannelsForActivity } from "@gql/tag";
import { GetBrief, UpdateBrief } from "@gql/brief";
import { GetActivityStatuses } from "@gql/activityStatus";
import { GetBlockingReminders } from "@gql/reminder";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { parseISO, format, startOfDay, endOfDay } from "date-fns";
import { sortBy, reverse, orderBy, uniq } from "lodash";

export default {
  page() {
    return {
      title: "Review Brief",
      meta: [
        {
          name: "description",
          content: `Review brief.`,
        },
      ],
    };
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {},
      showAlert: false,
      deletedActivities: [],
      reminders: [],
      debouncedReminderCheck: null,
      baseActivity: {
        dates: {},
        locations: [],
        channel: null,
        spokespersons: [],
      },
    };
  },
  computed: {
    userCanEdit() {
      if (!this.user) {
        return false;
      }
      if (this.user.isAdminUser) {
        return true;
      }
      if (this.user.selfApprove) {
        return true;
      }
      // Not sure about this
      return false;
    },
    briefLogs() {
      return this.brief
        ? this.brief.activities
          ? reverse(sortBy(this.brief.activities[0].logs, ["createdAt"]))
          : []
        : [];
    },
  },
  apollo: {
    user: LocalGetSelf,
    brief: {
      query: GetBrief,
      variables() {
        this.$log.warn(this.id);
        return {
          id: this.id,
        };
      },
      update(data) {
        if (!data.brief) {
          this.$router.push({ name: "404", params: { resource: "Brief" } });
        }
        this.$log.debug("Got Brief from API: ", data);
        if (data.brief.isConfidential && !this.user.isAdminUser) {
          this.$router.push({ name: "home" });
          return;
        }

        data.brief.team = data.brief.team
          ? data.brief.team.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }))
          : [];
        data.brief.tags = data.brief.tags.map((tag) => ({ ...tag, color: tag.color ? tag.color : tag.type.color }));
        data.brief.activities = data.brief.activities.map((activity) => ({
          ...activity,
          dates: {
            startDate: activity.startDate,
            endDate: activity.endDate,
          },
          spokespersons: activity.spokespersons.length > 0
            ? activity.spokespersons.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
          location: activity.locations.length > 0
            ? activity.locations.map(tag => ({
                ...tag,
                color: tag.color ? tag.color : tag.type.color,
              }))
            : [],
        }));
        setTimeout(this.checkForBlockingReminders, 1000);
        return data.brief;
      },
      fetchPolicy: "no-cache",
    },
    tags: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "generic" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Tags from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    spokespersons: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            categories: { some: { name: { equals: "spokesperson" } } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Spokespersons from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    locations: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "location" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Locations from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    groups: {
      query: GetPeople,
      variables() {
        return {
          companyId: this.user.company.id,
          category: "spokesperson",
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Groups from API: ", data);
        return orderBy(
          data.tags.map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    teamMembers: {
      query: GetPeople,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Team Members from API: ", data);
        return orderBy(
          data.tags.filter((tag) => (tag.person !== undefined && tag.person !== null)).map((tag) => ({
            ...tag,
            color: tag.color ? tag.color : tag.type.color,
          })),
          ["label"]
        );
      },
    },
    channels: {
      query: GetChannelsForActivity,
      variables() {
        return {
          companyId: this.user.company.id,
          orderBy: { label: "asc" },
        };
      },
      update: ({ tags }) => orderBy(tags, ["label"]),
      error(error) {
        this.$log.error("There was an error", error);
      },
      skip() {
        return !this.user;
      },
    },
    statuses: {
      query: GetActivityStatuses,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      update: ({ activityStatuses }) =>
        orderBy(
          activityStatuses.filter((status) => status.label !== "Ready for approval"),
          ["label"]
        ),
      error(error) {
        this.$log.error("There was an error", error);
      },
      skip() {
        return !this.user;
      },
    },
    reminders: {
      query: GetBlockingReminders,
      variables() {
        return {
          companyId: this.user ? this.user.company.id : "",
          startDate: format(parseISO(this.filter.startDate.gte)),
          endDate: format(parseISO(this.filter.endDate.lte)),
        };
      },
      skip() {
        // Skip this at the beginning, while we have no dates
        return true;
      },
    },
  },
  methods: {
    async updateBrief() {
      if (!this.userCanEdit) {
        return false;
      }
      this.isLoading = true;
      this.alert = {};
      this.showAlert = false;

      // Validate the form
      const validForm = await this.$refs.form.validate();

      // Manuel validation bc vee validate can't handle multiselects
      this.alert.message = [];
      for (const activity of this.brief.activities) {
        if (!activity.location) {
          this.showAlert = true;
          this.alert.type = "error";
          this.alert.title = "Error";
          this.alert.message.push("A location is required for every activity");
        }
        if (!activity.channel) {
          this.showAlert = true;
          this.alert.type = "error";
          this.alert.title = "Error";
          this.alert.message.push("A channel is required for every activity");
        }
      }
      if (this.showAlert || !validForm) {
        this.isLoading = false;
        this.showAlert = true;
        this.alert.type = "error";
        this.alert.title = "Error";
        this.alert.message.push("Please check the form below for errors");
        this.alert.message = uniq(this.alert.message);
        return false;
      }
      // Validation good, reset
      this.$refs.form.reset();

      try {
        await this.$apollo.mutate({
          mutation: UpdateBrief,
          variables: {
            id: this.brief.id,
            brief: {
              title: this.brief.subject,
              proposedDetails: this.brief.proposedDetails,
              isConfidential: this.brief.isConfidential,
              tags: { connect: this.brief.tags.map((tag) => ({ id: tag.id })) },
              team: { connect: this.brief.team.map((userTag) => ({ id: userTag.id })) },
              // Ugh. Activity fkeys to the user, brief to the tag
              owner: { connect: { id: this.brief.owner.id } },
              
              activities: {
                upsert: this.brief.activities.map((activity) => ({
                  create: {
                    channel: { connect: { id: activity.channel.id } },
                    status: { connect: { id: this.brief.activities[0].status.id } },
                    spokespersons: activity.spokespersons.length > 0 ? { connect: activity.spokespersons.map(tag => ({ id: tag.id })) } : undefined,
                    locations: activity.locations.length > 0 ? { connect: activity.locations.map(tag => ({ id: tag.id })) } : undefined,
                    startDate: format(parseISO(activity.dates.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                    endDate: format(parseISO(activity.dates.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),

                    createdBy: { connect: { id: this.user.id } },
                    company: { connect: { id: this.user.company.id } },
                  },
                  update: {
                    channel: { connect: { id: activity.channel.id } },
                    status: { connect: { id: this.brief.activities[0].status.id } },
                    spokespersons: activity.spokespersons.length > 0 ? { connect: activity.spokespersons.map(tag => ({ id: tag.id })) } : undefined,
                    locations: activity.locations.length > 0 ? { connect: activity.locations.map(tag => ({ id: tag.id })) } : undefined,
                    startDate: format(parseISO(activity.dates.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                    endDate: format(parseISO(activity.dates.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                  },
                  where: { id: activity.id },
                })),
              },
            },
          },
          update: (store, { data: { updateBrief } }) => {},
          error(err) {
            this.$log.error(err);
            this.showAlert = true;
            this.alert.type = "error";
            this.alert.title = "There was a problem";
            this.alert.message = err;
            this.isLoading = false;
          },
        });

        // Handle deletions as an extra step
        if (this.deletedActivities.length > 0) {
          await this.$apollo.mutate({
            mutation: UpdateBrief,
            variables: {
              id: this.brief.id,
              brief: {
                activities: {
                  delete: this.deletedActivities.map((id) => ({ id: id })),
                },
              },
            },
            update: (store, { data: { updateBrief } }) => {},
            error(err) {
              this.$log.error(err);
              this.showAlert = true;
              this.alert.type = "error";
              this.alert.title = "There was a problem";
              this.alert.message = err;
              this.isLoading = false;
            },
          });
        }

        // Success
        this.showAlert = true;
        this.alert.type = "success";
        this.alert.title = "Success";
        this.alert.message = "Brief updated";
        this.isLoading = false;
        this.$refs.form.reset();
        // Redirect
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 1000);
      } catch (err) {
        // Error
        this.showAlert = true;
        this.alert.type = "error";
        this.alert.title = "There was a problem";
        this.isLoading = false;
        this.alert.message = err;
      }
    },
    async updateStatus() {
      if (!this.userCanEdit) {
        return false;
      }
      this.isLoading = true;
      this.alert = {};
      this.showAlert = false;
      this.$log.debug({
        brief: {
          activities: {
            update: this.brief.activities.map((activitiy) => ({
              data: { status: { connect: { id: this.brief.activities[0].status.id } } },
              where: { id: activitiy.id },
            })),
          },
        },
      });

      try {
        await this.$apollo.mutate({
          mutation: UpdateBrief,
          variables: {
            id: this.brief.id,
            brief: {
              activities: {
                update: this.brief.activities.map((activitiy) => ({
                  data: { status: { connect: { id: this.brief.activities[0].status.id } } },
                  where: { id: activitiy.id },
                })),
              },
            },
          },
          update(store, data) {
            this.$log.debug(data);
          },
          error(err) {
            this.$log.error(err);
            this.showAlert = true;
            this.alert.type = "error";
            this.alert.title = "There was a problem";
            this.alert.message = err;
            this.isLoading = false;
          },
        });
        // Success
        this.showAlert = true;
        this.alert.type = "success";
        this.alert.title = "Success";
        this.alert.message = "Brief created";
        this.isLoading = false;
        this.$refs.form.reset();

        this.$apollo.queries.brief.refetch();
      } catch (err) {
        // Error
        this.showAlert = true;
        this.alert.type = "error";
        this.alert.title = "There was a problem";
        this.isLoading = false;
        this.alert.message = err;
      }
    },
    addActivity() {
      this.brief.activities.push({ ...this.baseActivity });
    },
    removeActivity(index) {
      this.$log.debug(index);
      this.deletedActivities.push(this.brief.activities[index].id);
      this.brief.activities = this.brief.activities.filter((activity, idx) => idx !== index);
      this.$forceUpdate();
      // this.brief.activities.forEach((activity, idx) => {
      //   if (idx <= index) {
      //     this["activity-" + idx] += 1;
      //   }
      // });
    },
    inputStatus(params) {
      this.$log.debug("Input status!", params);
      this.brief.activities[0].status = params;
    },
    async checkForBlockingReminders() {
      this.reminders = [];
      this.alert = {};
      this.showAlert = false;
      for (const activity of this.brief.activities) {
        // Get dates
        let startDate = activity.startDate;
        let endDate = activity.endDate;
        if (activity.dates) {
          startDate = activity.dates.startDate;
          endDate = activity.dates.endDate;
        }

        this.$log.debug("checkForBlockingReminders: ", startDate, endDate);
        if (!startDate || !endDate) {
          return false;
        }
        const reminders = await this.$apollo.query({
          query: GetBlockingReminders,
          variables: {
            companyId: this.user.company.id,
            startDate: format(startOfDay(parseISO(startDate)), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            endDate: format(endOfDay(parseISO(endDate)), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          },
        });
        this.$log.debug("checkForBlockingReminders - Results", reminders);

        // Set reminders
        this.reminders = this.reminders.concat(reminders.data.reminders);
      }
      this.$log.debug(this.reminders);
      if (this.reminders.length > 0) {
        this.showAlert = true;
        this.alert = {
          type: "error",
          title: "There was a problem",
          message: "There are blocking reminders coinciding with these dates",
        };
      }
    },
  },
};
</script>
